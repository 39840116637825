import { AnimatePresence, motion } from 'framer-motion'
import { Gauge, Gift, LucideIcon, Stars } from 'lucide-react'
import { useEffect, useState } from 'react'

import { cn } from '@/lib/utils'
import { Text } from '@/ui/Typography'

import { _T, useTranslations } from './helpers'

interface Props {
  className?: string
  showTopTriangle?: boolean
}

const CYCLE_DURATION = 5000 // 5 seconds per item

type TranslationKey =
  | 'item1header'
  | 'item1body'
  | 'item2header'
  | 'item2body'
  | 'item3header'
  | 'item3body'

interface BenefitItem {
  Icon: LucideIcon
  iconColor: string
  keys: {
    title: TranslationKey
    body: TranslationKey
  }
}

const benefitItems: BenefitItem[] = [
  {
    Icon: Gauge,
    iconColor: 'rgb(151, 71, 255)',
    keys: {
      title: 'item1header',
      body: 'item1body',
    },
  },
  {
    Icon: Gift,
    iconColor: 'rgb(0, 175, 0)',
    keys: {
      title: 'item2header',
      body: 'item2body',
    },
  },
  {
    Icon: Stars,
    iconColor: 'rgb(255, 184, 0)',
    keys: {
      title: 'item3header',
      body: 'item3body',
    },
  },
]

export const SignInBenefitsBlock = ({ className, showTopTriangle = false }: Props) => {
  const tr = useTranslations(translations)
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((current) => (current + 1) % benefitItems.length)
    }, CYCLE_DURATION)

    return () => clearInterval(timer)
  }, [])

  return (
    <div
      className={cn(
        'relative px-5 mt-2 pt-8 pb-8 flex flex-col justify-center container max-w-2xl',
        className
      )}>
      {showTopTriangle && (
        <div
          className={'h-6 w-6 bg-gray-200 absolute top-2 place-self-center rotate-45 rounded-md'}
        />
      )}
      <div className="bg-gray-200 p-4 rounded-md">
        <div className="relative h-14 flex items-center">
          {benefitItems.map((item, index) => (
            <AnimatePresence mode="wait" key={index}>
              {activeIndex === index && (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.5 }}
                  className="absolute w-full">
                  <Row
                    Icon={item.Icon}
                    iconColor={item.iconColor}
                    title={tr[item.keys.title]}
                    body={tr[item.keys.body]}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          ))}
        </div>
      </div>
    </div>
  )
}

const Row = (props: { Icon: LucideIcon; iconColor: string; title: string; body: string }) => {
  const { Icon, iconColor, title, body } = props
  return (
    <div className="flex flex-row items-center">
      <Icon className={'w-12 h-12 shrink-0'} color={iconColor} />
      <div className="flex flex-col ml-4">
        <Text level={3} weight="bold">
          {title}
        </Text>
        <Text level={3} weight="regular">
          {body}
        </Text>
      </div>
    </div>
  )
}

const translations = _T({
  en: {
    header: 'Benefits of signing in',
    item1header: 'Faster checkout',
    item1body: 'At all restaurants. No more entering details or payment information!',
    item2header: 'Automatic loyalty',
    item2body: 'With built in privacy that you control',
    item3header: 'And much more',
    item3body: 'Save favourites, your allergens, order history and so much more',
  },
  sv: {
    header: 'Fördelar med att logga in',
    item1header: 'Snabbare utcheckning',
    item1body: 'På alla restauranger. Inga fler detaljer eller betalningsinformation!',
    item2header: 'Automatisk lojalitet',
    item2body: 'Med inbyggd integritet som du kontrollerar',
    item3header: 'Och mycket mer',
    item3body: 'Spara favoriter, dina allergener, beställningshistorik och mycket mer',
  },
  no: {
    header: 'Fordeler med å logge inn',
    item1header: 'Raskere utsjekking',
    item1body: 'På alle restauranter. Ingen flere detaljer eller betalingsinformasjon!',
    item2header: 'Automatisk lojalitet',
    item2body: 'Med innebygd personvern som du kontrollerer',
    item3header: 'Og mye mer',
    item3body: 'Lagre favoritter, allergener, bestillingshistorikk og mye mer',
  },
})
