import * as Sentry from '@sentry/nextjs'
import * as Ably from 'ably'
import { AblyProvider } from 'ably/react'
import React, { useEffect } from 'react'

import { CurrencyContext, DEFAULT_CURRENCY_CODE } from '@/hooks/useCurrency'
import { RealtimeProvider } from '@/hooks/useListenForRealtimeEvent/RealtimeProvider'
import { SessionExpirationCheck, SessionProvider } from '@/hooks/useSession'
import { SideMenuProvider } from '@/hooks/useSideMenu/context'

import { CartProvider as InternalCartProvider, ToastsProvider } from '../../hooks'
import { ErrorFallback } from '../error-fallback'
import { SessionfulPageProps, SessionlessPageProps } from './types'

const getCurrencyContextValueFromProps = (
  props: SessionfulPageProps | SessionlessPageProps
): CurrencyContext => {
  const { information } = props
  const currencyCode = information?.currencyCode ?? DEFAULT_CURRENCY_CODE
  return { currencyCode }
}

export const SessionfulPage: React.FC<SessionfulPageProps> = ({ children, ...pageProps }) => {
  const { session, information } = pageProps

  const { sessionId, clientId } = session
  const ablyClient =
    clientId && new Ably.Realtime.Promise({ key: pageProps.ablySubscriptionToken, clientId })

  useEffect(() => {
    if (!ablyClient) {
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!session) {
    return null
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback code={'SESSIONFUL_PROVIDER_WRAPPER'} />}>
      <SessionProvider session={pageProps.session}>
        <CurrencyContext.Provider value={getCurrencyContextValueFromProps(pageProps)}>
          <AblyProvider client={ablyClient}>
            <RealtimeProvider
              ablySubscriptionToken={pageProps.ablySubscriptionToken}
              sessionId={sessionId}>
              <SessionExpirationCheck>
                <InternalCartProvider>
                  <ToastsProvider>
                    <SideMenuProvider
                      userProfile={pageProps.userProfile}
                      locationInformation={information}>
                      <Sentry.ErrorBoundary
                        fallback={<ErrorFallback code={'SESSIONFUL_PAGE_CONTENT'} />}>
                        {children}
                      </Sentry.ErrorBoundary>
                    </SideMenuProvider>
                  </ToastsProvider>
                </InternalCartProvider>
              </SessionExpirationCheck>
            </RealtimeProvider>
          </AblyProvider>
        </CurrencyContext.Provider>
      </SessionProvider>
    </Sentry.ErrorBoundary>
  )
}

export const SessionlessPage: React.FC<SessionlessPageProps> = (props) => {
  const { children, userProfile } = props

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback code={'SESSIONLESS_PROVIDER_WRAPPER'} />}>
      <ToastsProvider>
        <SideMenuProvider userProfile={userProfile}>
          <CurrencyContext.Provider value={getCurrencyContextValueFromProps(props)}>
            <Sentry.ErrorBoundary fallback={<ErrorFallback code={'SESSIONLESS_PAGE_CONTENT'} />}>
              {children}
            </Sentry.ErrorBoundary>
          </CurrencyContext.Provider>
        </SideMenuProvider>
      </ToastsProvider>
    </Sentry.ErrorBoundary>
  )
}
