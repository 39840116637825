import { faGear, faGift, faLifeRing, faReceipt, faUser } from '@fortawesome/pro-duotone-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { useRouter } from 'next/router'
import React from 'react'

import { UserProfileData } from '@/api-utils/service-requests/user-sessions'
import { _T, useTranslations } from '@/components/helpers'
import { SignInBenefitsBlock } from '@/components/SignInBenefitsBlock'
import { UserPageSection } from '@/components/user-profile/UserPageSection'
import { UserPageSectionRow } from '@/components/user-profile/UserPageSectionRow'
import { LocationInformation } from '@/models/types'
import { Button } from '@/ui/button'
import { getUserSignInURL } from '@/utils/user-utils'

import { useUserProfilePresenter } from '.'

type Props = {
  userProfile: UserProfileData | null
  locationInformation?: LocationInformation | null
}
export const SideMenuContent = (props: Props) => {
  const tr = useTranslations(translations)
  const userPresenter = useUserProfilePresenter(props.userProfile)

  if (!props.userProfile) {
    return <SignedOutContent locationInformation={props.locationInformation} />
  }

  let title = userPresenter.fullName
  const titleMaxLen = 30
  if (title.length > titleMaxLen) {
    title = title.substring(0, titleMaxLen) + '...'
  }

  return (
    <>
      <div className="px-6">
        <h2 className={'text-4xl font-medium'}>{title}</h2>
      </div>
      <UserPageSection hasTopBorder={false} className={'-mb-4'}>
        <UserPageSectionRow icon={faLifeRing} text={tr.help} href={tr.helpUrl} />
        <UserPageSectionRow icon={faReceipt} text={tr.previousOrders} href="/user/orders" />
        <UserPageSectionRow icon={faGift} text={tr.loyaltyPrograms} href="/user/rewards" />
        <UserPageSectionRow icon={faUser} text={tr.profile} href="/user/profile" />
        <UserPageSectionRow icon={faGear} text={tr.settings} href="/user/settings" />
      </UserPageSection>
      <UserPageSection
        title={props.locationInformation?.name ?? tr.locationInformation}
        hasTopBorder={true}>
        <UserPageSectionRow text={tr.aboutUs} icon={faInfoCircle} href={'/about'} />
      </UserPageSection>
    </>
  )
}

const SignedOutContent = ({
  locationInformation,
}: {
  locationInformation: LocationInformation | null | undefined
}) => {
  const tr = useTranslations(translations)

  const router = useRouter()

  const onSignInPageLinkClick = async () => {
    const loginUrl = getUserSignInURL()
    await router.push(loginUrl)
  }

  return (
    <>
      <div className="px-6">
        <h2 className={'text-4xl font-medium'}>{tr.signInTitle}</h2>
      </div>
      <div className={'px-6 pt-4'}>
        <Button className="w-full" onClick={onSignInPageLinkClick} variant="default" size="xl">
          {tr.signInButtonText}
        </Button>
        <SignInBenefitsBlock showTopTriangle={true} className={'px-0 pt-4'} />
      </div>

      <UserPageSection
        title={locationInformation?.name ?? tr.locationInformation}
        hasTopBorder={true}>
        <UserPageSectionRow text={tr.aboutUs} icon={faInfoCircle} href={'/about'} />
      </UserPageSection>
    </>
  )
}

export const translations = _T({
  en: {
    aboutUs: 'About us',
    signInTitle: 'Menu',
    signInButtonText: 'Sign in to Karma',
    previousOrders: 'My Orders',
    wallet: 'Wallet',
    help: 'Help',
    helpUrl: 'https://support.karma.life',
    comingSoon: 'Coming soon',
    profile: 'Profile',
    loyaltyPrograms: 'Loyalty programs',
    settings: 'Settings',
    profileMissingName: 'Name missing',
    locationInformation: 'Location information',
  },
  sv: {
    aboutUs: 'Om oss',
    signInTitle: 'Meny',
    signInButtonText: 'Logga in till Karma',
    previousOrders: 'Mina beställningar',
    wallet: 'Plånbok',
    help: 'Hjälp',
    helpUrl: 'https://support.karma.life',
    comingSoon: 'Kommer snart',
    profile: 'Profil',
    loyaltyPrograms: 'Lojalitetsprogram',
    settings: 'Inställningar',
    profileMissingName: 'Namn saknas',
    locationInformation: 'Information',
  },
  no: {
    aboutUs: 'Om oss',
    signInTitle: 'Meny',
    signInButtonText: 'Sign in to Karma',
    previousOrders: 'Mine bestillinger',
    wallet: 'Lommebok',
    help: 'Hjelp',
    helpUrl: 'https://support.karma.life',
    comingSoon: 'Kommer snart',
    profile: 'Profil',
    loyaltyPrograms: 'Lojalitetsprogram',
    settings: 'Innstillinger',
    profileMissingName: 'Navn mangler',
    locationInformation: 'Informasjon',
  },
})
