import { createContext, PropsWithChildren, useState } from 'react'

import { UserProfileData } from '@/api-utils/service-requests/user-sessions'
import { LocationInformation } from '@/models/types'

import { SideMenu } from './SideMenu'

export type SideMenuContext = {
  userProfile: UserProfileData | null
  locationInformation?: LocationInformation | null
  isSheetOpen: boolean
  setIsSheetOpen: (isSheetOpen: boolean) => void
}

export const SideMenuContext = createContext<SideMenuContext>({
  userProfile: null,
  locationInformation: null,
  isSheetOpen: false,
  setIsSheetOpen: () => ({}),
})

type Props = {
  userProfile?: UserProfileData | null
  locationInformation?: LocationInformation | null
}

export const SideMenuProvider = ({
  children,
  userProfile,
  locationInformation,
}: PropsWithChildren<Props>) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  return (
    <SideMenuContext.Provider
      value={{
        userProfile: userProfile ?? null,
        locationInformation: locationInformation ?? null,
        isSheetOpen,
        setIsSheetOpen,
      }}>
      <SideMenu userProfile={userProfile ?? null} locationInformation={locationInformation} />
      {children}
    </SideMenuContext.Provider>
  )
}
